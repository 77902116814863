import Blocks from 'components/blocks/blocks';
import SEO from 'components/seo/seo';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import imagesLoaded from 'imagesloaded';
import { handlePageLoaded } from 'components/trans/transLink';

import classNames from 'classnames';

import './cookiebot.template.scss';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      blocks: any[];
    };
  };
}

const CookiebotTemplate: React.FC<Props> = (props) => {
  const { title, id, seo } = props.pageContext;

  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);

    const script = document.createElement('script');
    script.src = 'https://consent.cookiebot.com/6225ac0f-487b-4105-b76c-d577e775094e/cd.js';
    script.async = true;
    document.getElementById('cookiebot-script')?.appendChild(script);
  }, []);

  return (
    <div className={classNames('page-template', { 'is-loading-complete': isLoaded })} id={id} ref={mainRef}>
      <SEO {...seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{title}</h5>
        </div>
        <div className="section-content page-content">
          <div className={classNames('cookiebot-container', { 'is-loaded': isLoaded })}>
            <div id="cookiebot-script" />

            <Blocks blocks={props.data.page.blocks} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CookiebotTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
